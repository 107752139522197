<template>
  <div class="mobility-services">
    <Header
      :isHeader="true"
      :back="true"
      :carInfo="true"
      :mobileResume="true"
      :dealer="true"
      :services="true"
      :step="currentStepIndex"
      :wash="true"
    />
    <Wrapper :ismain="true">
      <template v-slot:sidebar>
        <!-- Se precisar fazer mostrar um item no updatedResum, e aqui que adiciono ou removo o bind do item -->
        <Sidebar
          :desktopResume="true"
          :dealer="true"
          :services="true"
          :wash="true"
        />
      </template>
      <Title titleStyle="title" :text="translateString('mobilityServices')" />
      <PopUpWashServices
        v-if="popupWarningShow && !inspectionServices"
        :text="
          translateString('noWashServiceSelected') + translateString('vehicle')
        "
        :question="translateString('wantToProceedWash')"
        :firstBtn="translateString('addServices')"
        @action="actionPopUp()"
      ></PopUpWashServices>
      <br />
      <Card v-if="groupedServices.length === 0">
        <p class="sm-text">
          {{ translateString("noMobilityServicesAvailable") }}
        </p>
      </Card>
      <Card
        v-else
        v-for="(group, index) in groupedServices.filter((el) => el.length > 0)"
        :key="index"
      >
        <CheckboxContainer
          v-for="(service, index) in group"
          :key="index"
          checkboxClass="checkbox-round radio-checkbox "
          containerClass="left"
          inputType="checkbox"
          :id="'service-' + service.id"
          :value="service.name"
          :name="'group-' + service.groupID"
          :isChecked="
            servicesIDSelected.includes(Number(service.id)) ||
            (defaultMobilityOption && service.id === defaultMobilityOption.id)
          "
          @updateInput="updateInput($event)"
        >
          <LabelComplex :forLabel="'service-' + service.id">
            <div class="row">
              <Badge
                v-if="servicesWithPromo.includes(Number(service.id))"
                label="Promo"
                position="relative "
              />
              <div>
                <p class="sm-text">{{ service.name }}</p>
              </div>
            </div>
            <div
              v-if="servicesWithPromo.includes(Number(service.id))"
              class="price-container"
            >
              <p class="text old-price">
                {{ formatPrice(service.price, 2, "€") }}
              </p>
              <p class="text price">
                {{ formatPrice(service.price_Desc, 2, "€") }}
              </p>
            </div>
            <div v-else class="price-container">
              <p class="text price">
                {{ formatPrice(service.price, 2, "€") }}
              </p>
            </div>
          </LabelComplex>
        </CheckboxContainer>
        <p class="sm-text-grey">
          * {{ translateString("servicesWithConfirmation") }}
        </p>
      </Card>
      <template v-slot:footer>
        <Footer>
          <Button
            id="getAvailability"
            :label="translateString('continue')"
            :btnClass="ctaBtn"
            class="btn-primary align-btn-getAvailability"
            @action="next('getAvailability')"
          />
        </Footer>
      </template>
    </Wrapper>
    <Modal :msg="callError" :isActive="callError != ''" />
  </div>
</template>

<script>
import Badge from "@/components/Badge/Badge.vue";
import Button from "@/components/Buttons/Button.vue";
import Card from "@/components/Card/Card.vue";
import CheckboxContainer from "@/components/Checkbox/CheckboxContainer.vue";
import LabelComplex from "@/components/Checkbox/LabelComplex.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Modal from "@/components/Modal/Modal.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Title from "@/components/Title/Title.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";
import PopUpWashServices from "../components/PopUp/PopUpWashServices.vue";
import { mapState } from "vuex";

import { mapGetters } from "vuex";

import {
  formatPrice,
  groupServices,
  translateString,
} from "@/functions/functions.js";

export default {
  name: "MobilityServices",
  components: {
    Badge,
    Button,
    Card,
    CheckboxContainer,
    Footer,
    Header,
    LabelComplex,
    Modal,
    Sidebar,
    Title,
    Wrapper,
    PopUpWashServices,
  },
  data() {
    return {
      ctaBtn:
        this.$store.state.mobilityServiceSelected.length == 0
          ? "disabled  "
          : "",
      services: this.$store.getters.getMappedMobilityServices,
      groupedServices: groupServices(this.$store.state.mobilityServices, true),

      servicesSelected: [],
      servicesIDSelected: this.$store.state.mobilityServiceIDSelected,
      pastSelectedServices: this.$store.state.complementaryList.concat(
        this.$store.state.workIds,
        this.$store.state.maintenanceList
      ),

      mobilityTotal: 0,
      mobilityOldTotal: 0,
      servicesTotal: 0,

      priceTotalServicesSelected: this.$store.state.updatableServicesTotal,

      defaultMobilityOption: null,
      popupWarningShow: false,

      servicesWithPromo: [],
      campaignInfo: this.$store.getters.getCampaignInfo,
      hasCampaigns: this.$store.state.hasCampaigns,
      campaignsUsed: this.$store.state.campaignsUsed,
    };
  },
  computed: {
    finalError() {
      return this.$store.state.finalError;
    },
    servicesReady() {
      return this.$store.state.availabilityReady;
    },
    callError() {
      return this.$store.state.callError;
    },
    total() {
      return this.$store.state.servicesTotal;
    },
    ...mapGetters(["getCurrentStepIndex"]),

    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
    ...mapState(["markingServiceSelected", "washServicesTotal"]),
  },
  created() {
    if (
      this.markingServiceSelected &&
      this.markingServiceSelected.lstServicesId
    ) {
      const selectedIds = this.markingServiceSelected.lstServicesId;

      const allServices = this.services;

      // Encontro o primeiro serviço cujo id está na lista selectedIds
      const selectedService = allServices.find((service) =>
        selectedIds.includes(String(service.id))
      );

      if (selectedService) {
        // Adiciono o id do selectedService ao array servicesIDSelected
        this.servicesIDSelected.push(selectedService.id);
      } else {
        console.log("No service found with the selected ids");
      }
    }
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    if (this.$store.state.carPlate == "") {
      this.$router.replace({ name: "Homepage" });
    }
    this.$store.dispatch("clearSelectedInfo", "MobilityServices");

    if (this.hasCampaigns) this.checkCampaigns();
    if (this.groupedServices.length === 0) {
      this.ctaBtn = " ";
    }
  },
  methods: {
    formatPrice,
    translateString,
    checkCampaigns() {
      let addServices = this.servicesIDSelected.length == 0 ? true : false;

      this.services.filter((service) => {
        if (service.campaigns != null && service.campaigns.length > 0) {
          service.campaigns.forEach((campaign) => {
            if (
              (Array.isArray(campaign.lst_services_parent) &&
                campaign.lst_services_parent.filter((parentService) =>
                  this.pastSelectedServices.includes(parentService)
                ).length > 0) ||
              campaign.lst_services_parent == null
            ) {
              this.servicesWithPromo.push(Number(service.id));
              service["price_Desc"] = service.campaigns[0].price_Desc;
              if (addServices) {
                if (
                  service.campaigns[0].campaign_id == this.campaignInfo.uuid
                ) {
                  this.servicesIDSelected.push(Number(service.id));
                }
              }
            }
          });
        }
      });
    },
    updateInput(e) {
      const id = Number(e.id.split("-")[1]);
      if (e.checked) {
        if (!this.servicesIDSelected.includes(id))
          this.servicesIDSelected.push(id);
      } else {
        if (this.servicesIDSelected.indexOf(id) > -1) {
          this.servicesIDSelected.splice(
            this.servicesIDSelected.indexOf(id),
            1
          );
        }
      }
    },
    saveAllInfo() {
      this.services.forEach((service) => {
        if (this.servicesIDSelected.includes(Number(service.id))) {
          this.servicesSelected.push({
            id: service.id,
            name: service.name,
            price:
              service.price_Desc != null || undefined
                ? service.price_Desc
                : service.price,
          });
          this.mobilityTotal +=
            service.price_Desc != null || undefined
              ? service.price_Desc
              : service.price;
          this.mobilityOldTotal += service.price;

          if (this.mobilityTotal > 0) {
            this.servicesTotal += this.mobilityTotal;
          }
        }
        //Listar as campanhas utilizadas
        if (service.campaigns != null && service.campaigns.length > 0) {
          const campaign = service.campaigns.find((el) => {
            return el.price_Desc == service.price_Desc;
          });

          if (campaign != null) {
            if (!this.campaignsUsed.includes(campaign.campaign_id)) {
              this.campaignsUsed.push(campaign.campaign_id);
            }
          }
        }
      });

      this.$store.dispatch("selectOption", {
        selectedValue: "mobilityServiceIDSelected",
        value: this.servicesIDSelected,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "mobilityServiceSelected",
        value: this.servicesSelected,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "servicesTotal",
        value: this.total + this.servicesTotal,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "mobilityServicesTotal",
        value: this.mobilityTotal,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "mobilityServicesOldTotal",
        value: this.mobilityOldTotal,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "campaignsUsed",
        value: this.campaignsUsed,
      });
    },
    actionPopUp() {
      this.servicesIDSelected = [];
      this.popupWarningShow = false;
    },
    checkForServiceWithNegativeValues() {
      let foundNegativeService = false;

      // iterarando sobre o array de serviços
      this.$store.state.servicesSelectedCompletedList.forEach(
        (serviceGroup) => {
          serviceGroup.services.forEach((service) => {
            // Verificando se o id e o preço do serviço são -1
            if (service.price <= -0.7 && service.price > -2) {
              // Se o serviço tiver id e preço iguais a -1, defino a variável foundNegativeService como true
              foundNegativeService = true;
            }
          });
        }
      );
      // Verifico se algum serviço com id e preço iguais a -1 foi encontrado
      if (foundNegativeService) {
        this.inspectionServices = true;
      } else {
        this.inspectionServices = false;
      }
    },
    next(id) {
      this.checkForServiceWithNegativeValues();
      this.saveAllInfo();
      if (this.servicesTotal == 0 && this.washServicesTotal < 20 && this.inspectionServices == false && this.priceTotalServicesSelected < 20) {
        this.popupWarningShow = true;
      }
      if(!this.popupWarningShow){
        if (this.servicesReady) {
          this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex + 1);
          this.$router.push({ name: "Schedule" });
          this.$store.dispatch(
            "getAvailability",
            this.$store.state.dealerSelected.dealerID
          );
        } else {
          this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex + 1);
          this.$store.dispatch(
            "getAvailability",
            this.$store.state.dealerSelected.dealerID
          );
          this.$store.dispatch("selectOption", {
            selectedValue: "isLoading",
            value: id,
          });
        }
      }
    },
  },
  watch: {
    servicesIDSelected() {
      if (this.servicesIDSelected.length > 0) {
        this.ctaBtn = "";
      } else {
        this.ctaBtn = "disabled ";
      }
    },
    servicesReady() {
      if (this.servicesReady == true) {
        this.$router.push({ name: "Schedule" });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>
